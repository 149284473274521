import * as cookie from 'cookie';

const DAYS_90 = 1000 * 60 * 60 * 24 * 90;

export const defaultCookieOptions = () => ({
  path: '/',
  expires: new Date(Date.now() + DAYS_90),
});

export const set = (session = '') => {
  if (typeof document !== 'undefined') {
    document.cookie = cookie.serialize('v2AuthSession', session, defaultCookieOptions());
  }
};

export const get = () => cookie.parse(document.cookie).v2AuthSession;

export const clear = () => set();
