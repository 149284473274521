'use client';

import { createContext, useContext } from 'react';

type OpenClose = readonly [() => void, () => void];
const Context = createContext<OpenClose | undefined>(undefined);

const DialogProvider = ({ children, value }: React.PropsWithChildren<{ value: OpenClose }>) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);

export default DialogProvider;

export const useDialog = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
