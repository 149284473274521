import { useAuthDialogStore } from '../../stores/authDialog';
import { EnterCode } from './EnterCode';
import { SendEmail } from './SendEmail';

function DialogContinueWithEmail() {
  const { digitsKey } = useAuthDialogStore();
  return digitsKey?.length ? <EnterCode /> : <SendEmail />;
}

export default DialogContinueWithEmail;
