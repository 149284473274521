import { create } from 'zustand';

export type AuthDialogStore = {
  emailDialogOpened: boolean;
  email: string | null;
  digitsKey: string | null;

  setEmailDialogOpened: (emailDialogOpened: boolean) => void;
  setEmail: (email: string | null) => void;
  setDigitsKey: (digitsKey: string | null) => void;

  resetState: () => void;
};

const initialState = {
  emailDialogOpened: false,
  email: null,
  digitsKey: null,
} as AuthDialogStore;

export const useAuthDialogStore = create<AuthDialogStore>((set) => ({
  ...initialState,
  setEmailDialogOpened: (emailDialogOpened) => set(() => ({ emailDialogOpened })),
  setEmail: (email) => set(() => ({ email })),
  setDigitsKey: (digitsKey) => set(() => ({ digitsKey })),

  resetState: () => set(() => initialState),
}));
