const signOut = async (token: string) => {
  await fetch(`${process.env.NEXT_PUBLIC_V4_API_URL}/auth/invalidate`, {
    method: 'POST',
    body: '{}',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export default signOut;
