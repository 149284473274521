import { createContext, useContext } from 'react';

const Context = createContext<{
  portalContainerId?: string;
  className?: string;
}>({
  portalContainerId: undefined,
  className: undefined,
});

const DialogPortalContainerProvider = ({
  children,
  portalContainerId,
  className,
}: React.PropsWithChildren<{ portalContainerId: string; className?: string }>) => (
  <Context.Provider value={{ portalContainerId, className }}>{children}</Context.Provider>
);

export default DialogPortalContainerProvider;

export const useDialogPortalContainer = () => useContext(Context);
