import * as cookie from 'cookie';

const getTokenFromClientCookies = () => {
  const v2AuthSession = cookie.parse(document.cookie).v2AuthSession;

  if (v2AuthSession) {
    try {
      const { token } = JSON.parse(v2AuthSession);
      return String(token);
    } catch {
      return undefined;
    }
  }

  return undefined;
};

export default getTokenFromClientCookies;
