import client from '@/services/fetcher/client';

const sendMagicEmail = async (email: string) => {
  try {
    const response = await client.fetch('/auth/sendMagic', {
      method: 'POST',
      body: JSON.stringify({
        email,
        target: 'WANDER',
        useDigits: true,
      }),
    });

    if (response.status === 429) {
      return {
        error: 'Too many requests. Please try again later.',
      };
    }

    const data = await response.json();

    return { data };
  } catch (e) {
    return {
      error: 'Something went wrong.',
    };
  }
};

export default sendMagicEmail;
