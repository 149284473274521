import clx from '../../utils/clx';
import Icon from '../Icon';

export const makeInputDefaultClasses = (error = false) =>
  clx(
    'border-w-6003 placeholder:text-w-4004 w-full rounded-lg border bg-transparent  text-white focus:border-w-4004',
    error && 'border-error'
  );

const InputWrapper = ({ children, error }: React.PropsWithChildren<{ error?: string }>) => (
  <div className='relative w-full'>
    {children}

    {Boolean(error) && (
      <>
        <Icon name='warning' className='text-error absolute right-[10px] top-[10px] h-5 w-5' />
        <div className='text-error mt-2'>{error}</div>
      </>
    )}
  </div>
);

export default InputWrapper;
