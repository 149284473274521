'use client';

import { useMemo, useState } from 'react';

import * as DialogBase from '@radix-ui/react-dialog';
import clx from '../../utils/clx';
import Icon from '../Icon';
import { useDialogPortalContainer } from './DialogPortalContainerProvider';
import DialogProvider from './DialogProvider';

const getElementById = (id: string) => {
  if (typeof document === 'undefined') {
    return null;
  }

  return document.getElementById(id);
};

type Props = {
  title?: React.ReactNode;
  open?: boolean;
  trigger?: React.ReactNode;
  className?: string;
  closeButtonClassName?: string;
  onClose?: (force?: boolean) => void;
  closable?: boolean;
  fullyControlled?: boolean;
  onClickOverlay?: () => void;
  onClickClose?: () => void;
  overlayClassName?: string;
};

const Dialog = ({
  title,
  open,
  trigger,
  children,
  className,
  closeButtonClassName,
  onClose,
  closable = true,
  fullyControlled = false,
  onClickOverlay,
  onClickClose,
  overlayClassName,
}: React.PropsWithChildren<Props>) => {
  const [prevIsOpen, setPrevIsOpen] = useState(Boolean(open));
  const [isOpen, setOpen] = useState(Boolean(open));

  const { portalContainerId, className: containerClassName } = useDialogPortalContainer();

  if (open !== undefined && prevIsOpen !== open) {
    setPrevIsOpen(open);
    setOpen(open);
  }

  const value = useMemo(
    () =>
      [
        () => setOpen(true),
        () => {
          setOpen(false);
          onClose?.();
        },
      ] as const,
    [onClose]
  );

  return (
    <DialogProvider value={value}>
      <DialogBase.Root
        open={isOpen}
        onOpenChange={(next) => {
          if (fullyControlled) {
            return;
          }

          if (!next) {
            onClose?.();
          }

          setOpen(next);
        }}
      >
        {trigger ? <DialogBase.Trigger asChild>{trigger}</DialogBase.Trigger> : undefined}
        <DialogBase.Portal container={portalContainerId ? getElementById(portalContainerId) : undefined}>
          <DialogBase.Overlay
            className={clx(
              'data-[state=open]:animate-overlayShow fixed inset-0 z-50 backdrop-blur-md',
              overlayClassName
            )}
            onPointerDown={(event) => {
              if (!closable) {
                event.preventDefault();
                event.stopPropagation();
                return;
              }

              if (fullyControlled) {
                event.preventDefault();
                event.stopPropagation();
                onClickOverlay?.();
                return;
              }
            }}
          />
          <DialogBase.Content
            className={clx(
              'data-[state=open]:animate-contentShow border-2-dark-grey scrollbar-hide fixed left-1/2 top-1/2 z-50 h-auto max-h-svh min-w-[300px] -translate-x-1/2 -translate-y-1/2 overflow-scroll rounded-2xl border bg-black shadow-xl focus:outline-none',
              className,
              containerClassName
            )}
          >
            {title ? <DialogBase.Title className='sr-only'>{title}</DialogBase.Title> : undefined}
            {children}
            {closable ? (
              <DialogBase.Close asChild>
                <button
                  className={clx(
                    'btn btn-rounded btn-alt absolute right-5 top-4 ml-auto grid h-6 w-6 appearance-none',
                    closeButtonClassName,
                    'bg-operated'
                  )}
                  aria-label='Close'
                  onClick={() => {
                    if (fullyControlled) {
                      onClickClose?.();
                      return;
                    }
                  }}
                >
                  <Icon name='close' className='top-0 h-6 w-6' />
                </button>
              </DialogBase.Close>
            ) : undefined}
          </DialogBase.Content>
        </DialogBase.Portal>
      </DialogBase.Root>
    </DialogProvider>
  );
};

export default Dialog;

const Close = ({ children }: { children: React.ReactNode }) => <DialogBase.Close asChild>{children}</DialogBase.Close>;

Dialog.Close = Close;
