'use client';

import AuthCode from 'react-auth-code-input';

import clx from '../../utils/clx';

type Props = React.ComponentProps<typeof AuthCode> & React.HTMLProps<HTMLInputElement>;

const InputAuthCode = ({ inputClassName, ...props }: Props) => (
  <AuthCode
    {...props}
    containerClassName='flex justify-between'
    inputClassName={clx(
      'text-center w-12 border-w-6003 h-20 text-3xl bg-transparent border rounded-md',
      inputClassName
    )}
  />
);

export default InputAuthCode;
