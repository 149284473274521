'use client';

import { useEffect, useState } from 'react';

import { usePathname } from 'next/navigation';

import Button from '@wander/ui/src/components/Button/Button';
import Dialog from '@wander/ui/src/components/Dialog';
import Icon from '@wander/ui/src/components/Icon';

import ButtonLink from '@/components/ButtonLink';
import { useAuthDialogStore } from '../stores/authDialog';
import DialogContinueWithEmail from './DialogContinueWithEmail';

const providers = ['apple', 'google', 'facebook'];

const AuthenticationProviders = () => {
  const { emailDialogOpened, setEmailDialogOpened } = useAuthDialogStore();
  const [redirectTo, setRedirectTo] = useState<string | undefined>(() =>
    typeof window !== 'undefined' ? encodeURIComponent(window.location.href) : ''
  );

  const pathName = usePathname();

  useEffect(() => {
    setRedirectTo(encodeURIComponent(window.location.href));
  }, [pathName]);

  return emailDialogOpened ? (
    <DialogContinueWithEmail />
  ) : (
    <>
      <h2 className='mb-2 max-w-[300px] text-lg font-medium'>Sign up or log in to continue</h2>

      <div className='flex w-full flex-col gap-4'>
        {providers.map((name) => (
          <ButtonLink
            key={name}
            className='btn-invert'
            href={`${process.env.NEXT_PUBLIC_AUTH_SERVER_URL}/wander/${name}?redirect=${redirectTo}`}
          >
            <Icon name={name} className='top-0 h-5 w-5' />

            <span>
              Continue with <span className='capitalize'>{name}</span>
            </span>
          </ButtonLink>
        ))}

        <Button tabIndex={0} className='btn-invert' onClick={() => setEmailDialogOpened(true)}>
          <Icon name='email' className='top-0 h-5 w-5' />
          <span>Continue with email</span>
        </Button>
      </div>
    </>
  );
};

const DialogAuthentication = ({ open, onClose }: React.ComponentProps<typeof Dialog>) => {
  const { resetState } = useAuthDialogStore();
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose?.();
        resetState();
      }}
      className='grid w-full gap-4 bg-black p-6 pt-4 md:w-fit md:min-w-[400px]'
    >
      <AuthenticationProviders />
    </Dialog>
  );
};

export default DialogAuthentication;
