'use client';

import { create } from 'zustand';
import * as authSessionCookie from '../actions/authSessionCookie';
import emitLogout from '../actions/emitLogout';
import signOut from '../actions/signOut';
import refreshIfExpire from './refreshIfExpire';

type UserSession = {
  token?: string;
  refreshToken?: string;
  expiresAt?: number;
  status: 'ready' | 'loading' | 'error';
};

const useAuth = create<{
  user: UserSession;
  logout: () => Promise<void>;
  setUserSession: (user: UserSession) => void;
}>((set, get) => ({
  user: {
    status: 'loading',
  },
  logout: async () => {
    authSessionCookie.clear();
    const user = get().user;
    signOut(user.token);
    emitLogout();
    set(() => ({
      user: {
        status: 'ready',
      },
    }));
  },
  setUserSession: (user: UserSession) => set({ user }),
}));

export default useAuth;

(async () => {
  try {
    const authSession = authSessionCookie.get();
    if (authSession) {
      const { token, refreshToken, expiresAt } = JSON.parse(authSession);

      const session = await refreshIfExpire(token, refreshToken, expiresAt);

      if (session) {
        authSessionCookie.set(JSON.stringify(session));
      }

      useAuth.setState({
        user: session
          ? {
              ...session,
              status: 'ready',
            }
          : {
              token,
              refreshToken,
              expiresAt,
              status: 'ready',
            },
      });
    } else {
      useAuth.setState({
        user: {
          status: 'ready',
        },
      });
    }
  } catch {
    useAuth.setState({ user: { status: 'error' } });
    authSessionCookie.clear();
  }
})();
