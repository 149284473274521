import { create } from 'zustand';

type DialogState = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

const useAuthenticationDialog = create<DialogState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  toggle: (force?: boolean) => set((state) => ({ isOpen: force ?? !state.isOpen })),
}));

export default useAuthenticationDialog;
