import { useCallback, useState, useTransition } from 'react';

import ButtonAction from '@wander/ui/src/components/Button/ButtonAction';
import Icon from '@wander/ui/src/components/Icon';
import InputWithLabel from '@wander/ui/src/components/Input/InputWithLabel';

import sendMagicEmail from '../../actions/sendMagicEmail';
import { useAuthDialogStore } from '../../stores/authDialog';

// million-ignore
export function SendEmail() {
  const { email, setEmail, setDigitsKey, resetState } = useAuthDialogStore();
  const [error, setError] = useState<string | null>(null);
  const [isPending, startTransition] = useTransition();

  const handleSendEmail = useCallback(
    async (formData: FormData) => {
      const email = formData.get('email') as string;

      if (email?.length) {
        startTransition(async () => {
          try {
            const response = await sendMagicEmail(email);

            if ('error' in response) {
              throw new Error(response.error);
            }

            const { digitsKey } = response.data;

            if (digitsKey) {
              setEmail(email);
              setDigitsKey(digitsKey);
            }
          } catch (e) {
            setError(String(e).replace('Error: ', ''));
          }
        });
      }
    },
    [setDigitsKey, setEmail]
  );

  return (
    <form action={handleSendEmail} className='grid gap-4'>
      <div className='text-lg font-medium'>Continue with email</div>

      <InputWithLabel
        type='email'
        defaultValue={email || undefined}
        disabled={isPending}
        label='Email address'
        name='email'
        error={error ? '' : undefined}
        autoFocus
      />

      <ButtonAction loading={isPending} className='w-full'>
        Continue
      </ButtonAction>

      <div>
        {error?.length ? (
          <div className='mb-4 flex items-center gap-2 text-error'>
            <Icon name='warning' className='-mt-0.5 h-5 w-5 text-error' />
            {error}
          </div>
        ) : null}

        <div className='cursor-pointer font-medium text-w-3005 hover:underline' onClick={() => resetState()}>
          Go back
        </div>
      </div>
    </form>
  );
}
