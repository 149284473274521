import { useAuth, useIsAuthenticationReady } from '@/features/authentication';
import useAuthenticationDialog from './useAuthenticationDialog';
import useWanderUser from './useWanderUser';

type UserContext = {
  token?: string;
  toggleLoginDialog: (force?: boolean) => void;
  logout: () => Promise<void>;
  ready: boolean;
};

const useUser = (): UserContext & {
  user: ReturnType<typeof useWanderUser> | undefined;
} => {
  const {
    user: { token },
    logout,
  } = useAuth();
  let ready = useIsAuthenticationReady();
  const user = useWanderUser();
  const { toggle } = useAuthenticationDialog();

  if (token) {
    ready = !!user;
  }

  return {
    logout,
    toggleLoginDialog: toggle,
    ready,
    user,
  };
};

export default useUser;
