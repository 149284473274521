import { useEffect } from 'react';

import refreshSession from '../actions/refreshSession';

const TEN_HOURS = 10 * 60 * 60 * 1000;

const expireInLessThan = (lessThan = TEN_HOURS, expiresAt = 0) => {
  const time = new Date(expiresAt).getTime() - Date.now();
  return time < lessThan;
};

const refreshIfExpire = async <T>(
  token: string | undefined,
  refreshToken: string | undefined,
  expiresAt: number | undefined
) => {
  if (!token || !refreshToken || !expiresAt) {
    return;
  }

  if (expireInLessThan(TEN_HOURS, expiresAt)) {
    return refreshSession(token, refreshToken).then(({ token, refreshToken, sessionExpiry }) => ({
      token,
      refreshToken,
      expiresAt: sessionExpiry,
    }));
  }
};

export default refreshIfExpire;
