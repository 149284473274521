import Link from 'next/link';

import clx from '@wander/ui/src/utils/clx';

import { UrlObject } from 'url';

type Props = React.ComponentProps<typeof Link> & {
  disabled?: boolean;
  className?: string;
  useNativeAnchorElement?: boolean;
};

function formatHref(href: string | UrlObject | undefined): string | undefined {
  if (typeof href === 'object') {
    return href.pathname + (href.search ? '?' + new URLSearchParams(href.search).toString() : '');
  }
  return href;
}

const ButtonLink = ({ children, className, disabled, useNativeAnchorElement, ...props }: Props) => {
  const href = props.href ? formatHref(props.href) : undefined;

  return useNativeAnchorElement ? (
    <a className={clx('btn', disabled && 'btn-disabled', className)} {...props} href={href}>
      {children}
    </a>
  ) : (
    <Link className={clx('btn', disabled && 'btn-disabled', className)} {...props}>
      {children}
    </Link>
  );
};

export default ButtonLink;
