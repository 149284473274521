import client from '@/services/fetcher/client';

const refreshSession = async (token: string, refreshToken: string) => {
  const response = await client.fetch('/auth/refresh', {
    method: 'POST',
    body: JSON.stringify({
      token,
      refreshToken,
    }),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const result = await response.json();

  if (!response.ok) {
    throw new Error(result);
  }

  return result;
};

export default refreshSession;
