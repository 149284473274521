import { Suspense } from 'react';
import LoaderDots from '../Loader/LoaderDots';
import Button from './Button';

type Props = React.ComponentProps<typeof Button> & {
  loading?: boolean;
};

const ButtonAction = ({ children, loading, className, ...props }: Props) => (
  <Button disabled={loading} className={className} {...props}>
    <Suspense fallback={null}>{loading ? <LoaderDots className='w-16' /> : children}</Suspense>
  </Button>
);

export default ButtonAction;
