type Env = 'production';

export const makeUrl = (path: string, env?: Env) =>
  process.env.NODE_ENV === 'development' && env === 'production'
    ? new URL(path, 'https://v4.api.wander.com/')
    : new URL(path, process.env.NEXT_PUBLIC_V4_API_URL);

export const _fetch = async (endpoint: string | URL, init?: RequestInit | undefined): Promise<Response> => {
  const headers = init?.headers ? new Headers(init.headers) : new Headers();

  if (init?.body) {
    headers.set('Content-Type', 'application/json');
  }

  const url = endpoint instanceof URL ? endpoint : makeUrl(endpoint);

  return fetch(url, {
    ...init,
    headers,
  });
};

export const _withAuth =
  (getToken: () => string | undefined) =>
  async (endpoint: string | URL, init?: RequestInit | undefined): Promise<Response> => {
    const token = getToken();
    const headers = init?.headers ? new Headers(init.headers) : new Headers();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return _fetch(endpoint, {
      ...init,
      headers,
    });
  };

const DELIMITER = "\n\n";
export async function* _doFetchStream(endpoint: string | URL, init: RequestInit) {
  const response = await _fetch(endpoint, init)

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  let chunkBuffer = '';
  let chunkIndex = 0;


  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { value, done } = await reader.read();
    const text = decoder.decode(value, { stream: true });

    const isEmpty = !text || text.length === 0;

    if (isEmpty) {
      // Process the last chunk when the stream is done
      if (!done) {
        break;
      }
    }

    chunkBuffer += text;

    const hasDelimiter = chunkBuffer.includes(DELIMITER);
    if (!hasDelimiter) {
      // Process the last chunk when the stream is done
      if (!done) {
        console.debug('No delimiter found, waiting for more data');
        continue;
      }
    }

    const [first, ...rest] = chunkBuffer.split(DELIMITER);
    const chunk = first.trim().replaceAll('}{', '},{');
    const remainder = rest.join(DELIMITER);

    const isStaging = process.env.NEXT_PUBLIC_V4_API_URL.includes('staging');
    if (isStaging) {
      console.debug(`==============  Chunk #${chunkIndex + 1}  =====================`);
      console.debug('Chunk', chunk);
      console.debug('Remainder', remainder);
      console.debug('===================================');
    }

    // Remainder to be used for the next chunk of data
    chunkBuffer = remainder;

    if (chunk.length > 0) {
      try {
        yield {
          chunk: JSON.parse(chunk),
          index: chunkIndex++,
        }
      } catch (error) {
        console.error('Error parsing chunk', chunk, error);
      }
    }

    if (done) {
      break;
    }
  }
};

export const _fetchStream = async  <T>(endpoint: string | URL, {onChunk, ...init}: RequestInit & { onChunk: (payload: { chunk: T; index: number; }) => void }) => {
  for await (const chunk of _doFetchStream(endpoint, init)) {
    onChunk(chunk)
  }
}