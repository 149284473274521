'use client';

import useAuth from '../hooks/useAuth';
import DialogAuthentication from './DialogAuthentication';

type Props = {
  redirectTo: string;
};

const DialogAuthenticationWithRedirect = ({ redirectTo }: Props) => {
  const {
    user: { token, status },
  } = useAuth();

  if (status !== 'ready') {
    return undefined;
  }

  return (
    <DialogAuthentication
      open={!token}
      onClose={() => {
        if (!token) {
          window.location.href = redirectTo;
        }
      }}
    />
  );
};

export default DialogAuthenticationWithRedirect;
