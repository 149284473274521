import client from '@/services/fetcher/client';

const checkMagicDigits = async (digits: string, digitsKey: string) => {
  try {
    const data = await client
      .fetch('/auth/useMagicDigits', {
        method: 'POST',
        body: JSON.stringify({
          digits,
          digitsKey,
        }),
      })
      .then((res) => res.json());

    return { data };
  } catch (e) {
    return {
      // @ts-ignore
      error: String(e.data?.error || 'Invalid auth code'),
    };
  }
};

export default checkMagicDigits;
