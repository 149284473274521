import useAuth from './useAuth';

const useIsAuthenticationReady = () => {
  const {
    user: { status },
  } = useAuth();

  return status === 'ready';
};

export default useIsAuthenticationReady;
