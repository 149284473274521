import clx from '../../utils/clx';
import Input from './Input';

const InputWithLabel = ({
  label,
  inputClassName,
  className,
  innerRef,
  ...props
}: React.ComponentProps<typeof Input> & { label: string; inputClassName?: string }) => {
  return (
    <label className={clx('flex flex-col font-normal text-white', className)}>
      <span className='mb-2 text-sm'>{label}</span>
      <Input innerRef={innerRef} className={inputClassName} {...props} />
    </label>
  );
};

export default InputWithLabel;
