import client from '@/services/fetcher/client';
import type { MeUser } from '@wandercom/users-defs';
import useSWRImmutable from 'swr/immutable';
import { useEvent } from '../../../utils/events';
import useAuth from '../../authentication/hooks/useAuth';
import { REFETCH_USER } from '../../authentication/messages';

export type WanderUser = MeUser;

const useWanderUser = () => {
  const {
    user: { token },
    logout,
  } = useAuth();

  const { data, mutate, error } = useSWRImmutable(token, () =>
    client.authFetch('/guest-api/users/me').then((res) => res.json())
  );

  useEvent(REFETCH_USER, (user: WanderUser | undefined) => {
    mutate(user, user ? false : true);
  });

  if (error) {
    logout();
  }

  return data as WanderUser;
};

export default useWanderUser;
