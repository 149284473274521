import clx from '../../utils/clx';
import InputWrapper, { makeInputDefaultClasses } from './InputWrapper';

const Input = ({
  className,
  error,
  innerRef,
  ...props
}: Omit<React.HTMLProps<HTMLInputElement>, 'ref'> &
  React.ComponentProps<typeof InputWrapper> & {
    innerRef?: React.Ref<HTMLInputElement>;
  }) => (
  <InputWrapper error={error}>
    <input
      ref={innerRef}
      className={clx(makeInputDefaultClasses(error !== undefined), 'h-10 p-[10px]', className)}
      {...props}
    />
  </InputWrapper>
);

export default Input;
