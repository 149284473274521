// TODO "@/features/user/utils/getTokenFromClientCookies" this should live on service fetcher
import getTokenFromClientCookies from '@/features/user/utils/getTokenFromClientCookies';
import { _fetch, _fetchStream, _withAuth, makeUrl } from './_fetch';

export const authFetch = _withAuth(getTokenFromClientCookies);

const client = {
  url: makeUrl,
  fetch: _fetch,
  fetchStream: _fetchStream,
  authFetch,
};

export default client;
