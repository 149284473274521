import { useCallback, useState, useTransition } from 'react';

import Icon from '@wander/ui/src/components/Icon';
import InputAuthCode from '@wander/ui/src/components/Input/InputAuthCode';
import LoaderDots from '@wander/ui/src/components/Loader/LoaderDots';
import clx from '@wander/ui/src/utils/clx';

import trackSigninSignup from '@/features/tracking/server/actions/trackSigninSignup';
import { set as setAuthSessionCookie } from '../../actions/authSessionCookie';
import checkMagicDigits from '../../actions/checkMagicDigits';
import useAuth from '../../hooks/useAuth';
import { useAuthDialogStore } from '../../stores/authDialog';

// million-ignore
export function EnterCode() {
  const { resetState, email, digitsKey, setDigitsKey } = useAuthDialogStore();
  const { setUserSession } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [isPending, startTransition] = useTransition();

  const handleInput = useCallback(
    async (code: string) => {
      setError(null);

      if (code?.length === 6 && digitsKey) {
        startTransition(async () => {
          try {
            const response = await checkMagicDigits(code, digitsKey);

            if ('error' in response) {
              throw new Error(response.error);
            }

            const { token, refreshToken, sessionExpiry } = response.data;
            const session = { token, refreshToken, expiresAt: new Date(sessionExpiry).getTime() };

            setAuthSessionCookie(JSON.stringify(session));

            setUserSession({
              ...session,
              status: 'ready',
            });

            trackSigninSignup(window.location.search);
            resetState();
          } catch (e) {
            setError(String(e));
          }
        });
      }
    },
    [digitsKey, resetState, setUserSession]
  );

  return (
    <div className='grid'>
      <div className='mb-3 max-w-[300px] text-lg font-medium'>We sent you a 6-digit code to {email}</div>

      <div className='mb-6 text-w-3005'>Click the link or enter the code below to login</div>

      <InputAuthCode
        allowedCharacters='numeric'
        onChange={(e) => handleInput(e as string)}
        inputClassName={clx(error?.length && 'border-error text-error')}
      />

      <div className='mt-3'>
        {error?.length ? (
          <div className='mb-4 flex items-center gap-2 text-error'>
            <Icon name='warning' className='-mt-0.5 h-5 w-5 text-error' />
            {error}
          </div>
        ) : null}

        <div className='flex items-center justify-between'>
          <button className='btn btn-inline btn-invert btn-sm hover:underline' onClick={() => setDigitsKey('')}>
            Go back
          </button>
          {isPending ? <LoaderDots className='w-16' /> : null}
        </div>
      </div>
    </div>
  );
}
