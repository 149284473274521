import { useEffect } from 'react';

export const emitEvent = <T>(event: string, payload?: T) => {
  window.dispatchEvent(
    new CustomEvent(event, {
      detail: payload,
    })
  );
};

export const useEvent = <T>(event: string, callback: (payload?: T) => void) => {
  return useEffect(() => {
    const handleEvent = (event: CustomEventInit<T>) => callback(event.detail);
    window.addEventListener(event, handleEvent);
    return () => window.removeEventListener(event, handleEvent);
  }, [event, callback]);
};
